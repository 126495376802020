<template lang="pug">
transition-group(:css="false" @enter="enter" @leave="leave" @after-leave="afterLeave")
  slot
</template>
<script>
  export default {
    methods: {
      enter(el, complete) {
        this.$emit('enter', { el, complete });
      },
      leave(el, complete) {
        this.$emit('leave', { el, complete });
      },
      afterLeave() {
        this.$emit('afterLeave');
      },
    },
  };
</script>
